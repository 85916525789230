import { createForm, setResponse, SubmitHandler, valiForm } from '@modular-forms/solid'
import { Component } from 'solid-js'
import { AccountPartnerAddType, AccountPartnersAddFormProps, PartnerAddSchema } from '~/components/account-partners-add/account-partners-add.interfaces'
import { createContact, getContactId } from '~/services/database/contacts'
import formStyles from '~/components/forms/styles/simple-form.module.scss'
import Button from '~/components/button/button'
import TextField from '~/components/fields/text/text'
import FormError from '~/components/form-error/form-error'
import { GetContactIdResponse } from '~/types/database/contacts'
import { translations } from '~/translations'

const AccountPartnersAddForm: Component<AccountPartnersAddFormProps> = ({ contacts, onSuccess }) => {
  const contactExists = (id: string) => {
    return contacts.find(contact => contact.contact_id === id)
  }

  const [form, { Form, Field }] = createForm<AccountPartnerAddType>({
    validate: valiForm(PartnerAddSchema)
  })

  const onSubmit: SubmitHandler<AccountPartnerAddType> = async (values) => {
    const result = await getContactId(values) as GetContactIdResponse
    if(!result?.id){
      setResponse(form, {
        status: 'error',
        message: translations().dashboard.contacts.invite.errorNoResponse
      })
    }else if(result?.id && contactExists(result.id)){
      setResponse(form, {
        status: 'error',
        message: translations().dashboard.contacts.invite.errorAlreadyExists
      })
    }else{
      await createContact({ contactId: result.id })
      onSuccess()
    }
  }

  return (
    <Form onSubmit={onSubmit} class={[formStyles.form, formStyles.alignLeft].join(' ')}>
      <Field name="email">
        {(field, props) => (
          <TextField
            label={translations().dashboard.contacts.invite.email}
            description={translations().dashboard.contacts.invite.emailDescription}
            {...field}
            {...props}
          />
        )}
      </Field>

      <FormError response={form.response} />

      <Button
        type='submit'
        style='lagoon'
        isLoading={form.submitting}
      >
        {translations().general.actions.add}
      </Button>
    </Form>
  )
}

export default AccountPartnersAddForm
