import { InferOutput, object, pipe, string, email } from 'valibot'
import { AccountContact } from '~/types/account/contacts'

export const PartnerAddSchema = object({
  email: pipe(string(), email())
})

export type AccountPartnerAddType = InferOutput<typeof PartnerAddSchema>

export interface AccountPartnersAddProps {
  onSuccess: () => void
}

export interface AccountPartnersAddFormProps {
  contacts: AccountContact[]
  onSuccess: () => void
}

